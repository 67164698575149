export var CONFIG = {
    Local_API_Url: "http://localhost:5003/api/",
    Dev_API_Url: "https://ppplfa-dev.azurewebsites.net/api/"
};    
  
export var main_url: any = {
    api_url: CONFIG.Dev_API_Url  
}                                      
                       
export var API_URLS: any = {
    Generic_api_url : main_url.api_url,   
    Auth_url : "auth/login",
    App_Info_url : "applicationinfo",
    BI_url : "bi",    
    PI_url : "principals",
    LI_url : "loaninformation",
    PPP_url2 : "PPPSANonCash",
    FTER_url : "FTEredcCalc",
    FCAL_url:  "forgivecalc",
    Emp_One_url : "employeeone",
    Emp_Two_url : "employeetwo",
    Doc_Info_url : "docinfo",
    T1_T2_Total_url : "employeetotal",
    SHWR_url :"salaryhrlywagereduction",
    FTERWH_url : "fteredwageharbour",
    PDF_url : "pdf",
    EZ_PDF_url : "ezpdf",  
    Payroll_cost_url : "nonpayrollCostcalculation",
    PCO_url : "payrollcostone",
    PCT_url : "payrollcosttwo",
    PCTH_url : "payrollcostthree"
}    

export const HTTP_VERBS : any = {
    Get : "get",
    GetById : "getById",
    Post : "post",
    Update : "put",    
    Delete : "delete",
    Post2 : "post2",
    Get2 : "get2"
}   

export const WIZ_STEPS : any ={
    label1 : "Business Information",
    label2 : "Loan Information",
    label3 : "Schedule A Worksheet",
    label4 : "Schedule A",
    label5 : "Forgiveness Calculations",
    label6 : "Summary",
    label7 : "Documentation",
    label8 : "Payroll Accumulator"   
}

            