<div class="ng-header">
    <div class="ng-header-left left">
        <!-- <div class="toggle-menu-button left">
            <a class="icon" (click)="toggleMenu()"><i class="fa fa-angle-left arrow"></i></a>
        </div> -->
        <div class="logo right">
            <!-- <img src="assets/images/bg_logo.svg" class="logo-large" style="width: 150px;float:left;"> -->
            <img src="assets/images/logo.svg" (click)="back()" class="logo-large" style="cursor: pointer; width: 100px;float:left;margin-left: 15%;">
            <img src="assets/images/logo.svg" (click)="back()" style="cursor: pointer;" class="logo-small">

        </div>
    </div>
    <div class="ng-header-right right">
        <div class="ng-header-right-item right">
            <a class="icon" (click)="logout()"><i class="fa fa-sign-out-alt"></i></a>
        </div>
        <!-- <div class="ng-header-right-item right" (click)="themeChooser.toggle($event)">
            <a class="icon"><i class="fa fa-cog"></i></a>
        </div> -->
        <!-- <div class="ng-header-right-item right" (click)="showNotificationSidebar()">
            <a class="icon"><i class="fa fa-bell"></i><span class="badge">5</span></a>
        </div> -->
        <div class="ng-header-right-item right" (click)="userdetails.toggle($event)">
            <a class="icon"><span [innerHtml]="user.email" class="user-name"></span><i class="fa fa-user"></i></a>
        </div>
    </div>
</div>

<!-- user detials overlay panel -->
<p-overlayPanel #userdetails>
    <!-- <div><b>User Name: </b><i [innerHtml]="user.userName"></i></div> -->
    <div><b>Email Id: </b><i [innerHtml]="user.email"></i></div>
    <!-- <div><b>Birthdate: </b><i [innerHtml]="user.dob | date:'dd/MM/yyyy'"></i></div> -->
</p-overlayPanel>

<!-- notifications sidebar -->
<p-sidebar [(visible)]="displayNotifications" position="right">
    <div *ngFor="let notification of notifications; let i=index" class="notification-message">
        <div><b>Message: </b><i [innerHtml]="notification.message"></i></div>
        <div><i>Created On: </i><i [innerHtml]="notification.createdOn | date:'dd/MM/yyyy'"></i></div>
    </div>
</p-sidebar>

<!-- theme chooser overlay panel -->
<!-- <p-overlayPanel #themeChooser>
    Select Theme:
    <div class="ui-g">
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-teal')" style="background-color: #427976;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-orange')" style="background-color: #ffa43b;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-light')" style="background-color: #cccccc"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-purple')" style="background-color: #3e3eb1"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-Turquoise')" style="background-color:#04838f;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-green')" style="background-color: #1e8455;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-blue')" style="background-color: #007dbc;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-black')" style="background-color: #000;"></button>
        </div>
    </div>
</p-overlayPanel> -->