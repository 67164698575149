<div class="row error-box">
    <div class="col-sm-12">
        <div class="text-center">
            <h1> Error 404</h1>
            <h2 class="font-xl">
                <strong>
                    <i class="fa fa-fw fa-exclamation-triangle fa-lg"></i> Page <u>Not</u> Found
                </strong>
            </h2>
            <p>
                The page you requested could not be found, either contact your webmaster or try again.
            </p>
            <p>
                Use your browsers <b>Back</b> button to navigate to the page you have prevously come from.
            </p>
        </div>
    </div>
</div>