// #docregion
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import 'rxjs/add/observable/of';
import { ApiService } from './api.service';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthService {

    constructor(private router: Router,private apiService: ApiService) {
    }
}

